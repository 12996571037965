/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

require('./vendor/jquery.hmc.offsite-links.js');

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

      // Header Fixed on Scroll
      var original = $('header').offset().top;

      if ( $('.module-block_grid').length ) {
        original = $(window).height();
      }
    
      // Window Resize Block Grid Force Height
      window.onresize = function() {
        if ( $('.module-block_grid').length ) {
          original = $(this).height();
        }
        $(".module-block_grid").css('height', $(this).height());
      };

      $(window).scroll(function(){
        var sticky = $('header'),
            scroll = $(window).scrollTop();
      
        if ( scroll >= original ) {
          sticky.addClass('fixed');
        } else {
          sticky.removeClass('fixed');
        }
      });

      // Header Navigation Toggle
      $('.nav-toggle').click(function() {
        if ($(this).attr('aria-expanded') === 'false') {
          $('body').addClass('no-scroll');
          $('header').addClass('open');
          $('.primary').addClass('open');
          $(this).attr('aria-expanded', 'true');
        } else {
          $('body').removeClass('no-scroll');
          $('header').removeClass('open');
          $('.primary').removeClass('open');
          $(this).attr('aria-expanded', 'false');
        }
      });

      // Check Select Box
      $('select').change(function(){
        if (this.length > 0) { 
          $(this).addClass('item-selected');
        }
      });

      // Offsite Links
      $('body').hmcOffsiteLinks();

      // Anchor Current Page Nav Close
      $('a[href*="#"][aria-current="page"]').click(function() {
        $('.nav-toggle').click();
      });

      // "Parallax" Homepage logo
      function parallaxIt(e, target, movement) {
        var $this = $(".module-block_grid");
        var relX = e.pageX - $this.offset().left;
        var relY = e.pageY - $this.offset().top;
      
        TweenMax.to(target, 0.5, {
          x: (relX - $this.width() / 2) / $this.width() * movement,
          y: (relY - $this.height() / 2) / $this.height() * movement
        });
      }

      $(".module-block_grid").mousemove(function(e) {
        parallaxIt(e, ".home-logo", -10);
      });



      // Custom Cursor
      var clientX = -100;
      var clientY = -100;
      var innerCursor = document.querySelector(".cursor-point");
      var outerCursor = document.querySelector(".cursor-outer");
      var outerCursorBox = outerCursor.getBoundingClientRect();
      var outerCursorSpeed = 0;
      var showCursor = false;

      var initCursor = function initCursor() {
    
        const unveilCursor = () => {
          TweenMax.set(innerCursor, {
            x: clientX,
            y: clientY
          });
          TweenMax.set(outerCursor, {
            x: clientX - outerCursorBox.width / 2,
            y: clientY - outerCursorBox.height / 2
          });
          setTimeout(() => {
            outerCursorSpeed = 0.2;
          }, 100);
          showCursor = true;
        };
        document.addEventListener("mousemove", unveilCursor);
    
        document.addEventListener("mousemove", e => {
          clientX = e.clientX;
          clientY = e.clientY;
        });
    
        const render = () => {
          TweenMax.set(innerCursor, {
            x: clientX,
            y: clientY
          });
          if (!innerCursor.isStuck) {
            TweenMax.to(outerCursor, outerCursorSpeed, {
              x: clientX - outerCursorBox.width / 2,
              y: clientY - outerCursorBox.height / 2
            });
          }
          if (showCursor) {
            document.removeEventListener("mousemove", unveilCursor);
          }
          requestAnimationFrame(render);
        };
        requestAnimationFrame(render);
      };

      initCursor();

      $("a[data-featherlight]").hover(function () {
        $(".cursor-point").addClass('img-hover');
      },
      function() {
        $(".cursor-point").removeClass('img-hover');
      });

      $("a[data-featherlight]").featherlight({
        afterOpen: function(){
          $(".cursor-point").addClass('sub-hover');
        }, 
        afterClose: function(){
          $(".cursor-point").removeClass('sub-hover');
        }
      });

      // Interactive Background
      let APP;
      
      function initBG () {
         APP = new App();

         // Events
         document.addEventListener('mousemove', APP.mousemoveHandler, false);
         document.addEventListener('mouseleave', APP.mouseleaveHandler, false);
         window.addEventListener('resize', APP.resize, false);

         // Loop Render
         const loop = () => {
          APP.render();
          requestAnimationFrame(loop);
         };
         requestAnimationFrame(loop);
      }
    
      
      class App {
         constructor () {
            this.canvas = document.querySelector('canvas');
            this.context = this.canvas.getContext('2d');
            this.canvas.width = this.width = window.innerWidth;
            this.canvas.height = this.height = window.innerHeight;
            this.setupDots();
            
            this.resize = this.resize.bind(this);
            this.mousemoveHandler = this.mousemoveHandler.bind(this);
            this.mouseleaveHandler = this.mouseleaveHandler.bind(this);
         }
         
         setupDots () {
            this.dots = [];
            this.scl = 40;
            this.cols = this.width / this.scl;
            this.rows = this.height / this.scl;
            
            let id = 0;
            for (let x = 0; x < this.cols + 1; x += 1) {
               for (let y = 0; y < this.rows; y += 1) {
                  this.dots.push(new Dot(id, x * this.scl, y * this.scl, this.context, this.scl, this.rect));
                  id += 1;
               }
            }
         }
         
         resize () {
            this.canvas.width = this.width = window.innerWidth;
            this.canvas.height = this.height = window.innerHeight;
            this.setupDots();
         }
         
         mousemoveHandler (event) {
            this.dots.forEach(d => {
               d.mousemove(event, this.canvas);
            });
         }
         
         mouseleaveHandler () {
            this.dots.forEach(d => {
               d.isHover = false;
            });
         }
         
         render () {
            this.context.clearRect(0, 0, this.width, this.height);
            
            this.dots.forEach(d => {
               d.render();
            });
         }
      }
      
      class Dot {
         constructor (id, x, y, context, scl) {
            this.id = id;
            this.x = x;
            this.y = y;
            this.new = { x: x - 10, y: y - 10, radius: 20, color: '#101010' };
            this.radius = 20;
            
            this.context = context;
            this.scl = scl;
            this.isHover = false;
            this.isANimated = false;
         }
         
         mousemove (event, canvas) {
            const rect = canvas.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;

            this.isOuterMost = ((Math.abs(this.x - x) < (this.scl / 4 * 19)) && (Math.abs(this.y - y) < (this.scl / 4 * 19))) ? true : false;
            this.isOuter = ((Math.abs(this.x - x) < (this.scl / 4 * 14)) && (Math.abs(this.y - y) < (this.scl / 4 * 14))) ? true : false;
            this.isHover = ((Math.abs(this.x - x) < (this.scl / 4 * 9)) && (Math.abs(this.y - y) < (this.scl / 4 * 9))) ? true : false;
            this.isCenter = ((Math.abs(this.x - x) < (this.scl / 4 * 5)) && (Math.abs(this.y - y) < (this.scl / 4 * 5))) ? true : false;
            this.isClosest = ((Math.abs(this.x - x) < (this.scl / 4 * 2)) && (Math.abs(this.y - y) < (this.scl / 4 * 2))) ? true : false;
            if ( this.isOuterMost && !this.isOuter && !this.isHover && !this.isCenter && !this.isClosest) {
              TweenMax.to(this.new, 0.5, {
                 radius: 24,
                 x: this.x - 12,
                 y: this.y - 12,
                 color: '#101010'
               });
           } else if (this.isOuter && !this.isHover && !this.isCenter && !this.isClosest) {
              TweenMax.to(this.new, 0.5, {
                 radius: 30,
                 x: this.x - 15,
                 y: this.y - 15,
                 color: '#101010'
               });
           } else if (this.isHover && !this.isCenter && !this.isClosest) {
               TweenMax.to(this.new, 0.5, {
                  radius: 28,
                  x: this.x - 14,
                  y: this.y - 14,
                  color: '#1c1c1c'
                });
            } else if (this.isHover && this.isCenter) {
               TweenMax.to(this.new, 0.5, {
                  radius: this.isClosest ? 8 : 12,
                  x: this.isClosest ? this.x - 4 : this.x - 6,
                  y: this.isClosest ? this.y - 4 : this.y - 6,
                  color: '#242424'
               });
            } else {
               TweenMax.to(this.new, 0.5, {
                  radius: 20,
                  x: this.x - 10,
                  y: this.y - 10,
                  color: '#101010'
               });
            }
         }
         
         render () {
            this.context.beginPath();
            this.context.rect(this.new.x, this.new.y, this.new.radius, this.new.radius);
            this.context.fillStyle = this.new.color;
            this.context.fill();
         }
      }
      
      if ( $('canvas').length ) {
        initBG();
      }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $(".module-block_grid").css('height', $(window).height());
  
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
